













































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'Chem1301Lab3CalculationsFr',
  components: {CalculationInput, STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step18Calc: null,
        step20Calc: null,
        step32Calc1: null,
        step32Calc2: null,
        step36Calc1: null,
        step36Calc2: null,
      },
      rows: [
        {
          step: '18',
          input: 'step18Calc',
          calc: true,
          question: "Quel est le pH d'une solution de 0,1 M de HCl?",
        },
        {
          step: '20',
          input: 'step20Calc',
          calc: true,
          question: "Quel est le pH d'une solution de 0,1 M de NaOH?",
        },
        {
          step: '32 a)',
          input: 'step32Calc1',
          calc: true,
          question:
            "Calculez la concentration de l'acide fort en le diluant (en supposant que vous avez ajouté exactement 1 mL d'acide à 20 mL d'eau).",
        },
        {
          step: '32 b)',
          input: 'step32Calc2',
          calc: true,
          question: "Calculez le pH attendu de la solution d'acide diluée.",
        },
        {
          step: '36 a)',
          input: 'step36Calc1',
          calc: true,
          question:
            "Calculez la concentration de la base forte en la diluant (en supposant que vous avez ajouté exactement 1 mL de base à 20 mL d'eau).",
        },
        {
          step: '36 b)',
          input: 'step36Calc2',
          calc: true,
          question: 'Calculez le pH attendu de la solution de base diluée.',
        },
      ],
    };
  },
});
